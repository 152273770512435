<template>
  <div class="wrapper">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent';
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';

export default {
  name: "User",
  components: {
    SubHeader,
    FormDialogComponent
  },
  mixins:[getForm, refreshData],
  data() {
    return {
      parents: [],
      formUrl: "",
      form: null,
      filters:{},
      apiUrl:"",
      mutation: "",
    };
  },
  methods: {
    loggedAs() {
      if (this.$store.state.auth.loggedAs) {
        this.$store.dispatch('settings/SET_PARAM', {key: 'loggedAs', data: null}).then((e) => {
          this.$store.dispatch("auth/SET_LOGGED_AS", null).then((e) => {
              this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true })
          })
        })
      }else {
        this.$store.dispatch('settings/SET_PARAM', {key: 'loggedAs', data: this.user}).then((e) => {
          this.$store.dispatch("auth/SET_LOGGED_AS", this.user).then((e) => {
              this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true })
          })
        })
      } 
    },
  },
  computed: {
   
  },
  mounted() {
  },
};
</script>
